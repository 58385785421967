// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-template-dashboard-tsx": () => import("./../../../src/template/dashboard.tsx" /* webpackChunkName: "component---src-template-dashboard-tsx" */),
  "component---src-template-post-tsx": () => import("./../../../src/template/post.tsx" /* webpackChunkName: "component---src-template-post-tsx" */)
}

